
html, body, #root {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

textarea.input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
